import { Box, Text, VStack } from '@chakra-ui/react';
import { sectionsBoundaryStyles } from '@config';

export const TitleBox = () => (
  <VStack alignSelf="flex-center" alignItems="flex-start" spacing="15px" {...sectionsBoundaryStyles}>
    <Text variant="heading-2">What I do</Text>
    <Box>
      <Text variant="paragraph-1">I am passionate about combining motion design and video to create</Text>
      <Text variant="paragraph-1">dynamic visual content that brings brands to life in exciting new ways.</Text>
    </Box>
  </VStack>
);
