import process from 'process';

export const ENV: {
  SPLINE_HEADER_URL: string;
  API_BASE_URL: string;
  WID1_URL: string;
  WID2_URL: string;
  WID3_URL: string;
  SHOWREEL_VIDEO_URL: string;
  SHOWREEL_THUMBNAIL_URL: string;
  HEADER_MOBILE_URL: string;
  ENV: 'development' | 'production' | 'test';
} = {
  SPLINE_HEADER_URL: process.env.REACT_APP_SPLINE_HEADER_URL!,
  API_BASE_URL: process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL!,
  WID1_URL: process.env.REACT_APP_HOMEPAGE_WID1_URL!,
  WID2_URL: process.env.REACT_APP_HOMEPAGE_WID2_URL!,
  WID3_URL: process.env.REACT_APP_HOMEPAGE_WID3_URL!,
  SHOWREEL_VIDEO_URL: process.env.REACT_APP_HOMEPAGE_SHOWREEL_VIDEO_URL!,
  SHOWREEL_THUMBNAIL_URL: process.env.REACT_APP_HOMEPAGE_SHOWREEL_THUMBNAIL_URL!,
  HEADER_MOBILE_URL: process.env.REACT_APP_MOBILE_HEADER_URL!,
  ENV: process.env.NODE_ENV,
};
