import { BoxProps, VStack } from '@chakra-ui/react';
import { ButtonsBox } from '@components';
import { GoBack } from './GoBack';

type Props = {
  containerProps: BoxProps;
};

export const PDHeader = (props: Props) => {
  return (
    <VStack alignItems="stretch" spacing="50px" {...props.containerProps}>
      <ButtonsBox />
      <GoBack />
    </VStack>
  );
};
