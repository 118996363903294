import { Box, VStack } from '@chakra-ui/react';
import { Footer } from '@components';
import { sectionsBoundaryStyles } from '@config';
import { useParams } from 'react-router-dom';
import { PDTextItems } from './components/PDTextItems/PDTextItems';
import { SimilarProjects } from './components/SimilarProjects';

import { PDImagesAndVideosSection } from './components/PDImagesAndVideosSection/PDImagesAndVideosSection';
import { PDHeader } from './components/PDHeader/PDHeader';
import { TitleBox } from './components/PDHeader/TitleBox';
import { useProjectPage } from '@hooks';
import { useEffect } from 'react';
import { FirebaseController } from '@services';
import { logEvent } from 'firebase/analytics';

export const ProjectDetails = () => {
  const params = useParams<{ id: string }>();
  const { projectPage, isLoading, isIdle } = useProjectPage(params.id as string);

  useEffect(() => {
    logEvent(FirebaseController.analytics, 'select_content', { content_type: 'DetailsPage', item_id: params.id });
  }, [params.id]);

  if (isIdle || isLoading || !projectPage) return null;

  return (
    <Box>
      <VStack margin="auto" alignItems="center" spacing="50px" pt="25px">
        <PDHeader containerProps={sectionsBoundaryStyles} />
        <TitleBox
          title={projectPage.title}
          subtitle={projectPage.subtitle}
          description={projectPage.description}
          containerProps={sectionsBoundaryStyles}
        />
        <PDImagesAndVideosSection
          data={projectPage}
          containerProps={{ ...sectionsBoundaryStyles, w: { mobile: '100%' } }}
        />
        <PDTextItems data={projectPage?.textItems} containerProps={sectionsBoundaryStyles} />
        <SimilarProjects similarProjects={projectPage.similarProjectsTiles} containerProps={sectionsBoundaryStyles} />
      </VStack>
      <Footer mt="50px" />
    </Box>
  );
};
