import { Box, Text, VStack } from '@chakra-ui/react';
import { HomeModule } from '@modules';

type Props = {
  item: { title: string; desc: string; thumbnailUrl: string };
  tileWidth: any;
  idx: 1 | 2 | 3;
};

export const Item = (props: Props) => {
  const { handleOnLoad } = HomeModule.useHomeStore();
  return (
    <Box display="flex" flexDir="column" w={props.tileWidth}>
      <video
        playsInline
        muted
        autoPlay
        loop
        src={props.item.thumbnailUrl}
        onLoadedData={() => handleOnLoad(`wid${props.idx}`)}
      />
      <VStack px={{ mobile: 0, till1024: '20px' }} pt="20px" spacing="8px" alignItems="flex-start">
        <Text variant="heading-3">{props.item.title}</Text>
        <Text variant="paragraph-2">{props.item.desc}</Text>
      </VStack>
    </Box>
  );
};
