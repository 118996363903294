export const imageArray = [
  {
    src: require('./1_Mangusta.png'),
    alt: 'Mangusta',
    width: 173,
    height: 174,
  },
  {
    src: require('./2_Mcdonald.png'),
    alt: 'Mcdonald',
    width: 162,
    height: 174,
  },
  {
    src: require('./3_Grailz.png'),
    alt: 'Grailtz',
    width: 212,
    height: 174,
  },
  {
    src: require('./4_RTV.png'),
    alt: 'RTV',
    width: 264,
    height: 174,
  },
  {
    src: require('./5_wkdzik.png'),
    alt: 'wkdzik',
    width: 249,
    height: 174,
  },
  {
    src: require('./6_Lajkonik.png'),
    alt: 'Lajkonik',
    width: 175,
    height: 174,
  },
  {
    src: require('./7_pko.png'),
    alt: 'Pko',
    width: 153,
    height: 174,
  },
  {
    src: require('./8_lotnisko.png'),
    alt: 'Lotnisko',
    width: 291,
    height: 174,
  },
];
