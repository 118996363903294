import { BoxProps, VStack } from '@chakra-ui/react';
import { AspectRatioEnum, calculateTileWidth, ENV } from '@config';

import { ItemBox } from './components/ItemBox';
import { TitleBox } from './components/TitleBox';

type Props = BoxProps;

export const WhatIDoSection = (props: Props) => {
  const items = [
    {
      title: '2D Animation',
      thumbnailUrl: ENV.WID1_URL,
      desc: `Add depth and emotion to static graphics, bringing them to life with dynamic movement and personality.`,
    },
    {
      title: '3D Animation',
      thumbnailUrl: ENV.WID2_URL,
      desc: `Endless possibilities for modeling and animating products and concepts that showcase every detail, from design to functionality.`,
    },
    {
      title: 'Video',
      thumbnailUrl: ENV.WID3_URL,

      desc: `Bring your products to life, highlighting their unique features and benefits in a way that resonates with your target audience`,
    },
  ];
  const tileWidth = calculateTileWidth(AspectRatioEnum._1_1);
  return (
    <VStack w="100%" textAlign="left" spacing="30px" {...props}>
      <TitleBox />
      <ItemBox items={items} calculatedTileWidth={tileWidth} />
    </VStack>
  );
};
