import { BoxProps, Image, VStack } from '@chakra-ui/react';
import { VideoPlayer } from '@components';
import { constants } from '@config';
import { ProjectPage } from '@kubamichalkiewicz/shared';
import { orderBy } from 'lodash';

type Props = {
  data: ProjectPage;
  containerProps: BoxProps;
};

export const PDImagesAndVideosSection = (props: Props) => (
  <VStack spacing={{ mobile: '3vw', till1024: '1.5vw' }} {...props.containerProps}>
    {orderBy(
      [
        ...props.data?.images.map((it) => ({ ...it, type: 'image' })),
        ...props.data?.videos.map((it) => ({ ...it, type: 'video' })),
      ],
      'order',
    ).map((image) => {
      if (image.type === 'image')
        return (
          <Image
            src={image.thumbnailUrl}
            key={image.thumbnailUrl}
            width={{
              mobile: '100%',
              till1024: `${constants.HP_SECTION_MAX_WIDTH}vw`,
              till1440: `${constants.MAX_WIDTH * constants.HP_SECTION_MAX_WIDTH * 0.01}px`,
            }}
          />
        );
      else return <VideoPlayer key={image.thumbnailUrl} customData={image as any} containerProps={{ width: '100%' }} />;
    })}
  </VStack>
);
