import { useMediaQuery } from '@chakra-ui/react';
import { mobileMediaQuery } from '@config';

export const withIsMobile = <ComponentProps extends {}>(
  WrappedComponent: React.FunctionComponent<ComponentProps & { isMobile: boolean }>,
) => {
  const ComponentWithIsMobile = (props: ComponentProps) => {
    const [isMobile] = useMediaQuery(mobileMediaQuery);

    return <WrappedComponent isMobile={isMobile} {...props} />;
  };

  return ComponentWithIsMobile;
};
