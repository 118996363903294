import { createContext, ReactNode, useContext } from 'react';
import { GlobalStateStore } from './global-state.store';

const GlobalStateContext = createContext<GlobalStateStore>({} as GlobalStateStore);

type Props = {
  children: ReactNode;
};

export const globalStateStore = new GlobalStateStore();

export const GlobalStateContextProvider = ({ children }: Props) => (
  <GlobalStateContext.Provider value={globalStateStore}>{children}</GlobalStateContext.Provider>
);

export const useGlobalStore = () => useContext(GlobalStateContext);
