export const constants = {
  TOAST_DURATION: 3500,
  MAX_WIDTH: 1440,
  HP_SECTION_MAX_WIDTH: 85,
  ELEMENT_IDS: {
    FOOTER: 'footer',
  },
};

export enum AspectRatioEnum {
  _1_1 = '1:1',
  _2_1 = '2:1',
  _3_1 = '3:1',
  _16_9 = '16:9',
  _4_3 = '4:3',
}

export const sectionsBoundaryStyles = {
  width: {
    mobile: '91vw',
    till1024: `${constants.HP_SECTION_MAX_WIDTH}vw`,
    till1440: `${constants.HP_SECTION_MAX_WIDTH}vw`,
    max: `${constants.MAX_WIDTH * constants.HP_SECTION_MAX_WIDTH * 0.01}px`,
  },
};

export const calculateTileWidth = (aspectRatio: AspectRatioEnum) => {
  if (aspectRatio === AspectRatioEnum._3_1)
    return {
      till1024: `${constants.HP_SECTION_MAX_WIDTH}vw`,
      till1440: `${constants.HP_SECTION_MAX_WIDTH}vw`,
      max: `${constants.MAX_WIDTH * constants.HP_SECTION_MAX_WIDTH * 0.01}px`,
    };
  else if (aspectRatio === AspectRatioEnum._1_1) {
    return {
      mobile: `calc((${constants.HP_SECTION_MAX_WIDTH}vw - 2vw) / 3)`,
      till1024: `calc((${constants.HP_SECTION_MAX_WIDTH}vw - 2vw) / 3)`,
      till1440: `calc((${constants.HP_SECTION_MAX_WIDTH}vw - 2vw) / 3)`,
      max: `calc((${constants.MAX_WIDTH * constants.HP_SECTION_MAX_WIDTH * 0.01}px - 2vw) / 3)`,
    };
  } else {
    return {
      till1024: `calc(1vw + (2 * calc((${constants.HP_SECTION_MAX_WIDTH}vw - 2vw) / 3)))`,
      till1440: `calc(1vw + (2 * calc((${constants.HP_SECTION_MAX_WIDTH}vw - 2vw) / 3)))`,
      max: `calc(1vw + (2 * calc((${constants.HP_SECTION_MAX_WIDTH * constants.MAX_WIDTH * 0.01}px - 2vw) / 3)))`,
    };
  }
};

export const calculateTileHeight = () => ({
  till1024: `calc(${constants.HP_SECTION_MAX_WIDTH}vw/3)`,
  till1440: `calc(${constants.HP_SECTION_MAX_WIDTH}vw/3)`,
  max: `${(constants.MAX_WIDTH * constants.HP_SECTION_MAX_WIDTH * 0.01) / 3}px`,
});

export const mobileMediaQuery = '(max-width: 600px)';
export const tabletMediaQuery = '(min-width: 601px) and (max-width: 1024px)';
export const webMediaQuery = '(min-width: 1025px)';
