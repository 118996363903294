import { extendTheme, TextProps } from '@chakra-ui/react';
import { constants } from './constants';

export const theme = extendTheme({
  colors: {
    primary: {
      100: '#87D0DA',
      200: '#000000',
    },
    neutral: {
      100: '#FAFAFA',
      95: '#F2F2F3',
      90: '#E4E5E7',
      70: '#AEB0B7',
      50: '#787C87',
      40: '#484A51',
      20: '#202124',
      0: '#0C0C0D',
    },
  },

  breakpoints: {
    mobile: '0px',
    till1024: '600px',
    till1440: '1024px',
    max: '1440px',
  },
  components: {
    Text: {
      baseStyle: { fontFamily: 'KumbhSans' },
      variants: {
        // cienki textitem projectdetails
        'text-4-300': {
          fontSize: { mobile: '2.5vw', till1024: '1.25vw', till1440: `${constants.MAX_WIDTH * 0.0125}` },
          fontWeight: 300,
        },

        'heading-1': {
          fontWeight: 700,
          fontSize: { till1440: '68px', till1024: '58px', mobile: '40px' },
          lineHeight: { till1440: '68px', till1024: '58px', mobile: '40px' },
          color: 'neutral.20',
        } as TextProps,
        'heading-2': {
          fontWeight: 600,
          fontSize: { till1440: '38px', till1024: '38px', mobile: '27px' },
          lineHeight: { till1440: '38px', till1024: '38px', mobile: '27px' },
          color: 'neutral.20',
        } as TextProps,
        'heading-3': {
          fontWeight: 600,
          fontSize: { till1440: '24px', till1024: '18px', mobile: '20px' },
          lineHeight: { till1440: '24px', till1024: '18px', mobile: '30px' },
          color: 'neutral.20',
        } as TextProps,
        'paragraph-1': {
          fontWeight: 400,
          fontSize: { till1440: '20px', till1024: '20px', mobile: '20px' },
          lineHeight: { till1440: '30px', till1024: '30px', mobile: '30px' },
          color: 'neutral.40',
        } as TextProps,
        'paragraph-2': {
          fontWeight: 400,
          fontSize: { till1440: '16px', till1024: '16px', mobile: '16px' },
          lineHeight: { till1440: '24px', till1024: '24px', mobile: '24px' },
          color: 'neutral.40',
        } as TextProps,
        'unusual-text': {
          fontWeight: 600,
          fontSize: { till1440: '20px', till1024: '20px', mobile: '20px' },
          lineHeight: { till1440: '20px', till1024: '20px', mobile: '20px' },
          color: 'neutral.20',
        } as TextProps,
        'unusual-text-2': {
          fontWeight: 600,
          fontSize: { till1440: '20px', till1024: '18px', mobile: '20px' },
          lineHeight: { till1440: '20px', till1024: '18px', mobile: '24px' },
          color: 'neutral.20',
        },
      },
    },
  },
});
