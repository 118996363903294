import { createContext, ReactNode, useContext } from 'react';
import { HomeStore } from './home-store.store';

type Props = {
  children: ReactNode;
};

const HomeStoreContext = createContext<HomeStore>({} as HomeStore);

export const HomeStoreProvider = ({ children }: Props) => {
  return <HomeStoreContext.Provider value={new HomeStore()}>{children}</HomeStoreContext.Provider>;
};

export const useHomeStore = () => useContext(HomeStoreContext);
