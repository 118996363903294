import { BoxProps, HStack, Text, VStack } from '@chakra-ui/react';
import { ProjectPage } from '@kubamichalkiewicz/shared';

type Props = {
  data?: ProjectPage['textItems'];
  containerProps: BoxProps;
};

export const PDTextItems = ({ data, containerProps }: Props) => (
  <VStack alignSelf="center" alignItems="stretch" spacing="0" {...containerProps}>
    {data?.map((it) => (
      <HStack key={it.order} spacing={{ mobile: '1vw', till1024: '0.5vw' }} position="relative" alignItems="flex-start">
        <Text variant="paragraph-1" fontWeight="600" whiteSpace="nowrap" color="neutral.20">
          {it.title}:
        </Text>
        <Text variant="paragraph-1" w="100%" color="neutral.20">
          {it.desc}
        </Text>
      </HStack>
    ))}
  </VStack>
);
