import { getProjects, QueryKeyEnum } from '@kubamichalkiewicz/shared';
import { FirebaseController } from '@services';
import { orderBy } from 'lodash';
import { useQuery } from 'react-query';

export const useProjects = () => {
  const { data, isLoading, isIdle } = useQuery({
    queryKey: QueryKeyEnum.PROJECTS,
    queryFn: () => getProjects(FirebaseController.database),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return {
    projects: orderBy(data, 'createdAt', 'desc'),
    isLoading,
    isIdle,
  };
};
