import { globalStateStore } from '@services';
import { action, makeAutoObservable, observable } from 'mobx';

type ComponentNameToLoad = 'header' | 'wid1' | 'wid2' | 'wid3';

export class HomeStore {
  public componentLoadedComponents;
  public isLoaded;

  constructor() {
    this.componentLoadedComponents = [] as ComponentNameToLoad[];
    this.isLoaded = false;

    makeAutoObservable(this, {
      isLoaded: observable,
      handleOnLoad: action,
    });
  }

  handleOnLoad = (componentName: ComponentNameToLoad) => {
    this.componentLoadedComponents.push(componentName);
    if (
      this.componentLoadedComponents.includes('header') &&
      this.componentLoadedComponents.includes('wid1') &&
      this.componentLoadedComponents.includes('wid2') &&
      this.componentLoadedComponents.includes('wid3')
    ) {
      this.isLoaded = true;
      globalStateStore.setWasHomeModuleRenderedDuringSession(true);
    }
  };
}
