import { Box, BoxProps, HStack, Image, Text, VStack } from '@chakra-ui/react';

import { AspectRatioEnum, calculateTileWidth, Routes } from '@config';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useProjectTiles } from '@hooks';
import { withIsMobile } from '@hocs';
import { ProjectPage, ProjectTile } from '@kubamichalkiewicz/shared';

type Props = {
  similarProjects: ProjectPage['similarProjectsTiles'];
  containerProps: BoxProps;
};

export const SimilarProjects = withIsMobile<Props>(({ isMobile, similarProjects, containerProps }) => {
  const navigate = useNavigate();
  const { projectTiles } = useProjectTiles();

  const navigateToProjectDetails = (projectId: string) => {
    navigate(Routes.PROJECT_DETAILS.replace(':id', projectId));
  };

  const pt = (() => {
    const coll = [] as ProjectTile[];
    _.orderBy(similarProjects, 'order').forEach((sp) => {
      if (sp.hidden) return;
      const tile = projectTiles?.find((pt) => pt.id === sp.tileId);
      if (!tile) return;
      coll.push(tile);
    });
    return coll;
  })();

  if (isMobile)
    return (
      <MobileSimilarProjects
        containerProps={containerProps}
        similarProjects={pt}
        navigateToProjectDetails={navigateToProjectDetails}
      />
    );
  return (
    <WebSimilarProjects
      containerProps={containerProps}
      similarProjects={pt}
      navigateToProjectDetails={navigateToProjectDetails}
    />
  );
});

const WebSimilarProjects = ({
  similarProjects,
  containerProps,
  navigateToProjectDetails,
}: {
  similarProjects: ProjectTile[];
  containerProps: BoxProps;
  navigateToProjectDetails: (projectId: string) => void;
}) => {
  return (
    <Box>
      {!!similarProjects.length && (
        <Text variant="heading-2" mb="15px">
          Similar projects
        </Text>
      )}
      <VStack alignItems="flex-start" spacing="1.5vw" position="relative" {...containerProps}>
        <Box position="relative" display="flex" alignItems="center">
          <HStack spacing="0" justifyContent="center" w="100%">
            {similarProjects.slice(0, 3).map((it) => (
              <Box key={`web-carousel-${it.id}`} p="0.5vw">
                <Image
                  src={it.carouselThumbnailUrl}
                  _hover={{
                    cursor: 'pointer',
                  }}
                  w={calculateTileWidth(AspectRatioEnum._4_3)}
                  onClick={() => navigateToProjectDetails(it.projectId)}
                />
                <Text variant="heading-3" h="1.75vw" pt="7.5px" fontWeight="500">
                  {it.title}
                </Text>
              </Box>
            ))}
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};

const MobileSimilarProjects = ({
  similarProjects,
  containerProps,
  navigateToProjectDetails,
}: {
  similarProjects: ProjectTile[];
  containerProps: BoxProps;
  navigateToProjectDetails: (projectId: string) => void;
}) => {
  const { projectTiles } = useProjectTiles();
  if (!projectTiles?.length) return null;

  return (
    <VStack pb="4vw" alignItems="flex-start" w="100%" spacing="2.5vw" {...containerProps}>
      {!!similarProjects.length && (
        <Text variant="heading-2" mb="5px">
          Similar projects
        </Text>
      )}
      {similarProjects.slice(0, 2).map((projectTile) => (
        <Box
          key={`mobile-similar-projects-${projectTile.id}`}
          onClick={() => navigateToProjectDetails(projectTile.projectId)}
        >
          <Image src={projectTile.carouselThumbnailUrl} />
          <Text variant="heading-3">{projectTile.title}</Text>
        </Box>
      ))}
    </VStack>
  );
};
