import { Text, VStack } from '@chakra-ui/react';
import { Footer, VideoPlayer } from '@components';
import { constants, Routes, sectionsBoundaryStyles } from '@config';
import { useProjectRows, useProjectTiles } from '@hooks';
import { ProjectRow, ProjectTile, ProjectTilesSection } from '@kubamichalkiewicz/shared';

import { useNavigate } from 'react-router-dom';

import { Header, WhatIDoSection } from './components';
import { LogoCarousel } from './components/LogoCarousel/LogoCarousel';

export const HomeComponent = () => {
  const navigate = useNavigate();

  const handleTilePress = (_row: ProjectRow, tile: ProjectTile) => {
    navigate(Routes.PROJECT_DETAILS.replace(':id', tile.projectId));
  };

  const { projectRows } = useProjectRows();
  const { projectTiles } = useProjectTiles();

  return (
    <>
      <Header />
      <VStack
        spacing={{
          mobile: '50px',
          till1024: '75px',
        }}
        margin="auto"
        overflow="hidden"
        mb="50px"
      >
        <VideoPlayer
          containerProps={{
            width: {
              mobile: '91vw',
              till1024: `${constants.HP_SECTION_MAX_WIDTH - 15}vw`,
              till1440: `${constants.HP_SECTION_MAX_WIDTH - 15}vw`,
              max: `${constants.MAX_WIDTH * (constants.HP_SECTION_MAX_WIDTH - 15) * 0.01}px`,
            },
          }}
        />
        <WhatIDoSection w={{ ...sectionsBoundaryStyles.width, mobile: '100vw' }} />
        <VStack w={{ ...sectionsBoundaryStyles.width, mobile: '91vw' }}>
          <Text variant="heading-2" mb="20px">
            My recent projects
          </Text>
          <ProjectTilesSection projectRows={projectRows} projectTiles={projectTiles} onTilePress={handleTilePress} />
        </VStack>
      </VStack>
      <LogoCarousel margin="auto" w={{ ...sectionsBoundaryStyles.width, mobile: '91vw' }} />
      <Footer />
    </>
  );
};
