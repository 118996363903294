import { action, makeAutoObservable, observable } from 'mobx';

export class GlobalStateStore {
  public wasHomeModuleRenderedDuringSession: boolean;
  public didDisplayedNewPageModal: boolean;

  constructor() {
    this.wasHomeModuleRenderedDuringSession = false;
    this.didDisplayedNewPageModal = false;
    makeAutoObservable(this, {
      wasHomeModuleRenderedDuringSession: observable,
      didDisplayedNewPageModal: observable,
      setWasHomeModuleRenderedDuringSession: action,
      getWasHomeModuleRenderedDuringSession: action,
    });
  }

  setWasHomeModuleRenderedDuringSession = (value: boolean) => (this.wasHomeModuleRenderedDuringSession = value);

  getWasHomeModuleRenderedDuringSession = () => this.wasHomeModuleRenderedDuringSession;

  setDidDisplayedNewPageModal = () => (this.didDisplayedNewPageModal = true);
}
