import ReactDOM from 'react-dom/client';
import './index.css';

import { ChakraProvider, PortalManager } from '@chakra-ui/react';
import { theme } from '@config';
import { router } from './config/router';
import { RouterProvider } from 'react-router-dom';
import { GlobalStateContextProvider, QueryClientProvider } from '@services';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ChakraProvider theme={theme}>
    <PortalManager zIndex={100}>
      <QueryClientProvider>
        <GlobalStateContextProvider>
          <RouterProvider router={router} />
        </GlobalStateContextProvider>
      </QueryClientProvider>
    </PortalManager>
  </ChakraProvider>,
);
