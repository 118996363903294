import { Box, BoxProps, HStack, Text, VStack } from '@chakra-ui/react';
import { constants, sectionsBoundaryStyles } from '@config';
import { withIsMobile } from '@hocs';

type Props = BoxProps;
export const Footer = withIsMobile<Props>(({ isMobile, ...rest }) => {
  if (isMobile)
    return (
      <Box
        id={constants.ELEMENT_IDS.FOOTER}
        pb="25px"
        pt="50px"
        {...sectionsBoundaryStyles}
        margin="auto"
        position="relative"
        overflow="hidden"
      >
        <VStack alignItems="flex-start" {...rest} spacing="20px" zIndex="1">
          <Box>
            <Text variant="heading-2">
              Looking for animated content that will make your brand stand out? I'm here to help!
            </Text>
            <Text variant="heading-2">
              <a href="mailto:k.michalkiewicz1@gmail.com">
                <u>Get in touch</u>
              </a>
            </Text>
          </Box>
          <Box zIndex="1">
            <Text variant="paragraph-1">+48 693 189 896 </Text>
            <Text variant="paragraph-1">k.michalkiewicz1@gmail.com</Text>
            <Text variant="paragraph-1">Warsaw, Poland</Text>
          </Box>
        </VStack>
        <Box
          position="absolute"
          bg="neutral.95"
          w="80vw"
          h="80vw"
          maxW="750px"
          maxH="750px"
          rounded="full"
          zIndex="0"
          bottom="0px"
          transform="translateY(70%)"
        />
      </Box>
    );

  return (
    <Box display="flex" id={constants.ELEMENT_IDS.FOOTER} pb="25px" pt="100px" position="relative" overflow="hidden">
      <VStack spacing="50px" alignSelf="center" w="100%">
        <VStack textAlign="center" spacing="0px" w="100%" zIndex="2">
          <Text variant="heading-2">Looking for animated content</Text>
          <Text variant="heading-2">that will make your brand</Text>
          <Text variant="heading-2">stand out? I'm here to help!</Text>
          <Text variant="heading-2" textDecorationLine="underline">
            <a href="mailto:k.michalkiewicz1@gmail.com">Get in touch</a>
          </Text>
        </VStack>

        <HStack spacing="15px" zIndex="2">
          <Text>+48 693 189 896</Text>
          <Text>|</Text>
          <Text>k.michalkiewicz1@gmail.com</Text>
        </HStack>
        <Box
          position="absolute"
          bg="neutral.95"
          w="80vw"
          h="80vw"
          maxW="750px"
          maxH="750px"
          rounded="full"
          zIndex="0"
          bottom="0px"
          transform="translateY(70%)"
        />
      </VStack>
    </Box>
  );
});
