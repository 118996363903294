import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, initializeAnalytics } from 'firebase/analytics';
import { Database, getDatabase } from 'firebase/database';
import { FirebaseStorage, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
class FBController {
  app!: FirebaseApp;
  analytics!: Analytics;
  database!: Database;
  storage!: FirebaseStorage;

  init = () => {
    this.app = initializeApp({
      apiKey: process.env.REACT_APP_API_KEY,
      appId: process.env.REACT_APP_APP_ID,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    });
    this.analytics = initializeAnalytics(this.app);
    this.database = getDatabase(this.app);
    this.storage = getStorage(this.app);
  };

  uploadFile = async (file: File): Promise<string> => {
    const storageRef = ref(this.storage, `/files_new/${v4()}`);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadUrl = await getDownloadURL(snapshot.ref);

    return downloadUrl;
  };
}

const FirebaseController = new FBController();
FirebaseController.init();

export { FirebaseController };
