import { Box, BoxProps } from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './play-button.css';
import { renderToString } from 'react-dom/server';
import { ENV } from '@config';
import { PlayIcon } from '@kubamichalkiewicz/shared';

type Props = {
  containerProps?: BoxProps;
  customData?: {
    thumbnailUrl: string;
    videoUrl: string;
  };
};

export const VideoPlayer = (props: Props) => {
  const videoRef = useRef<any>(null);
  const playerRef = useRef<any>(null);
  const options = useMemo(
    () => ({
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      poster: props.customData?.thumbnailUrl ?? ENV.SHOWREEL_THUMBNAIL_URL,
      sources: [
        {
          src: props.customData?.videoUrl ?? ENV.SHOWREEL_VIDEO_URL,
          type: 'video/mp4',
        },
      ],
      controlBar: {
        pictureInPictureToggle: false,
      },
    }),
    [props.customData],
  );

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoRef.current.appendChild(videoElement);
      playerRef.current = videojs(videoElement, options, () => {});
      (playerRef.current.getChild('BigPlayButton').el_ as HTMLButtonElement).replaceWith(
        _createBigButtonReplacementElement(),
      );
    }
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  const _createBigButtonReplacementElement = () => {
    const container = document.createElement('button');
    container.setAttribute('id', 'play-button');
    container.addEventListener('click', () => {
      playerRef.current.play();
      container.remove();
    });

    container.innerHTML = renderToString(
      <div id="play-button-content">
        <div style={{ transform: 'translateX(2.5px)' }}>
          <PlayIcon color="white" size="16px" />
        </div>
      </div>,
    );
    return container;
  };

  return (
    <Box position="relative" display="flex" {...props.containerProps} justifyContent="center">
      <div ref={videoRef} style={{ width: '100%', height: '100%' }}></div>
    </Box>
  );
};
