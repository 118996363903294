import { Box, BoxProps, Image as CImage, ScaleFade } from '@chakra-ui/react';
import { imageArray } from './images/imageArray';
import C, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { withIsMobile } from '@hocs';

type Props = BoxProps;

export const LogoCarousel = withIsMobile<Props>(({ isMobile, ...props }) => {
  const [minimumCarouselHeight, setMinimumCarouselHeight] = useState(0);

  const [isIn, setIn] = useState(false);
  const restoreTimeoutRef = useRef<NodeJS.Timeout>();

  const settings = useMemo((): Settings => {
    return {
      dots: false,
      infinite: true,
      centerPadding: '0px',
      slidesToShow: 1,
      slidesToScroll: 8,
      autoplay: true,
      speed: 15000 * (isMobile ? 0.8 : 1),
      autoplaySpeed: 0,
      cssEase: 'linear',
      focusOnSelect: false,
      arrows: false,
      draggable: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      swipe: false,
      variableWidth: true,
      adaptiveHeight: false,
      swipeToSlide: false,
      responsive: undefined,
      initialSlide: 0,
      // waitForAnimate: true,
    };
  }, [isMobile]);

  const resetCarousel = () => {
    setIn(false);
    if (restoreTimeoutRef.current) {
      clearTimeout(restoreTimeoutRef.current);
    }
    restoreTimeoutRef.current = setTimeout(() => setIn(true), 1250);
  };

  useEffect(() => {
    window.addEventListener('resize', resetCarousel);
    setIn(true);
    return () => window.removeEventListener('resize', resetCarousel);
  }, []);

  const onAnimationComplete = () => {
    if (minimumCarouselHeight === 0) {
      const height = document.querySelector('#carouselContainer')?.clientHeight;
      if (height) setMinimumCarouselHeight(height);
    }
  };
  return (
    <Box minHeight={minimumCarouselHeight}>
      <ScaleFade
        unmountOnExit
        in={isIn}
        transition={{ enter: { duration: 1 }, exit: { duration: 0.125 } }}
        onAnimationComplete={onAnimationComplete}
        initialScale={0.9}
      >
        <Box {...props} id="carouselContainer">
          <C {...settings}>
            {imageArray.map((item, idx) => (
              <CImage
                key={item.alt + idx}
                src={item.src}
                alt={item.alt}
                style={{ width: item.width * (isMobile ? 0.5 : 1) }}
              />
            ))}
          </C>
        </Box>
      </ScaleFade>
    </Box>
  );
});
