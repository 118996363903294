import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider as DefaultClientProvider } from 'react-query';

type Props = {
  children: ReactNode;
};

export const QueryClientProvider = ({ children }: Props) => {
  const toast = useToast();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: Infinity,
        staleTime: Infinity,
      },
      mutations: {
        onSuccess(data) {
          toast({
            duration: 3500,
            position: 'top',
            status: 'success',
            title: 'Mutation success!',
            description: (data as string) ?? 'Unknown mutation success',
          });
        },
        onError(error) {
          toast({
            duration: 3500,
            position: 'top',
            status: 'error',
            title: 'Mutation error!',
            description: ((error as AxiosError)?.response?.data as string) ?? '',
          });
        },
      },
    },
  });
  return <DefaultClientProvider client={queryClient}>{children}</DefaultClientProvider>;
};
