import { Box, Image, VStack } from '@chakra-ui/react';
import { ButtonsBox } from '@components';
import { ENV, sectionsBoundaryStyles } from '@config';
import { withIsMobile } from '@hocs';
import { HomeModule } from '@modules';
import { SplineObject } from './components/SplineObject';
import { TextBox } from './components/TextBox';

export const Header = withIsMobile(({ isMobile }) => {
  const { handleOnLoad } = HomeModule.useHomeStore();

  const _handleOnLoad = () => handleOnLoad('header');

  return (
    <>
      {!isMobile ? (
        <Box h="700px" w="100%" position="relative" display="flex" alignItems="center" justifyContent="center">
          <SplineObject onLoad={_handleOnLoad} />
          <Box position="absolute" pointerEvents="none" {...sectionsBoundaryStyles}>
            <TextBox isMobile={isMobile} />
          </Box>
          <Box position="absolute" pointerEvents="none" {...sectionsBoundaryStyles} top="30px">
            <ButtonsBox />
          </Box>
        </Box>
      ) : (
        <>
          <VStack w="100%" {...sectionsBoundaryStyles} alignItems="stretch" margin="auto" mt="30px" spacing="55px">
            <Box pointerEvents="none">
              <ButtonsBox />
            </Box>
            <Box pointerEvents="none">
              <TextBox isMobile={true} />
            </Box>
          </VStack>
          <Image src={ENV.HEADER_MOBILE_URL} w="100%" onLoad={_handleOnLoad} />
        </>
      )}
    </>
  );
});
