import { getProjectDetailsPage, QueryKeyEnum } from '@kubamichalkiewicz/shared';
import { FirebaseController } from '@services';
import { useQuery } from 'react-query';

export const useProjectPage = (projectId: string) => {
  const { data, isLoading, isIdle } = useQuery({
    queryKey: [QueryKeyEnum.PROJECT_PAGE, projectId],
    queryFn: () => getProjectDetailsPage(FirebaseController.database, projectId),
  });

  return {
    projectPage: data,
    isLoading,
    isIdle,
  };
};
