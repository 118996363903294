import { Box, HStack } from '@chakra-ui/react';
import { withIsMobile } from '@hocs';
import { Item } from './Item';

type Props = {
  items: { title: string; desc: string; thumbnailUrl: string }[];
  calculatedTileWidth: any;
};

export const ItemBox = withIsMobile<Props>(({ isMobile, ...rest }) => {
  if (isMobile) return <ItemBoxMobile {...rest} />;
  return (
    <HStack w="100%" justifyContent="space-between" alignItems="flex-start">
      {rest.items.map((item, idx) => (
        <Item idx={(idx + 1) as 1 | 2 | 3} key={idx} item={item} tileWidth={rest.calculatedTileWidth} />
      ))}
    </HStack>
  );
});

export const ItemBoxMobile = (props: Props) => (
  <Box w="100%">
    <HStack
      px="4.5vw"
      columnGap="2.5vw"
      overflow="scroll"
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      alignItems="flex-start"
    >
      {props.items.map((item, idx) => (
        <Box key={idx}>
          <Item idx={(idx + 1) as 1 | 2 | 3} key={idx} item={item} tileWidth="40vw" />
        </Box>
      ))}
    </HStack>
  </Box>
);
