import { Box, BoxProps, Text } from '@chakra-ui/react';
import { constants } from '@config';
import { withIsMobile } from '@hocs';
import { LogoIcon } from '@kubamichalkiewicz/shared';

type Props = BoxProps;

export const ButtonsBox = withIsMobile<Props>(({ isMobile, ...rest }) => {
  const handleContentClick = () => {
    document.querySelector(`#${constants.ELEMENT_IDS.FOOTER}`)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box zIndex="modal" display="flex" justifyContent="space-between" alignItems="center" {...rest}>
      <LogoIcon size={isMobile ? '45px' : '50px'} />
      <Box
        borderWidth="3px"
        borderColor="neutral.40"
        rounded="md"
        _hover={{ cursor: 'pointer', backgroundColor: 'white', transform: 'scale(1.01)' }}
        onClick={handleContentClick}
        pointerEvents="all"
      >
        <Text variant="unusual-text" px="15px" py="12.5px">
          Contact me
        </Text>
      </Box>
    </Box>
  );
});
