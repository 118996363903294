import { HStack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Routes } from '@config';
import { ArrowLeftIcon } from '@kubamichalkiewicz/shared';

export const GoBack = () => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  return (
    <HStack
      alignSelf="flex-start"
      _hover={{ cursor: 'pointer' }}
      onClick={() => navigate(Routes.ROOT)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div
        style={{ display: 'flex', flexDirection: 'column' }}
        animate={{
          x: isHovered ? '-8px' : 0,
        }}
      >
        <ArrowLeftIcon size={30} strokeWidth={1.5} />
      </motion.div>
      <Text variant="unusual-text">Back to all</Text>
    </HStack>
  );
};
