import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { withIsMobile } from '@hocs';
import { ArrowLeftIcon } from '@kubamichalkiewicz/shared';
import { useGlobalStore } from '@services';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

export const NewPageAvailableModal = withIsMobile<object>(
  observer(({ isMobile }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { setDidDisplayedNewPageModal, didDisplayedNewPageModal } = useGlobalStore();

    useEffect(() => {
      if (!didDisplayedNewPageModal) {
        setDidDisplayedNewPageModal();
        setTimeout(() => onOpen(), 1500);
      }
    }, [didDisplayedNewPageModal, onOpen, setDidDisplayedNewPageModal]);

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay backdropFilter="blur(5px)" />
        <ModalContent
          minW={isMobile ? undefined : 650}
          maxW={isMobile ? undefined : 650}
          marginLeft={isMobile ? 4 : undefined}
          marginRight={isMobile ? 4 : undefined}
          padding={4}
          border="3px solid black"
          paddingBottom={10}
        >
          <ModalHeader>
            <Text style={{ fontSize: '48px', fontWeight: '600' }}>Hey!</Text>
          </ModalHeader>
          <ModalCloseButton onClick={onClose} userSelect="none" size="lg" _focusVisible={{ border: 'none' }} />
          <ModalBody style={{ display: 'flex', flexDirection: 'column', rowGap: 32 }}>
            <Text style={{ paddingRight: isMobile ? undefined : 50 }}>
              You've landed on my old website. This place has evolved to bring even more creativity and inspiration.
            </Text>
            <Text style={{ paddingRight: isMobile ? undefined : 50 }}>
              Check out Palm Vision Studio - our new animation and post-production studio. Visit us and see our latest
              projects!
            </Text>
            <Box
              borderWidth="3px"
              borderColor="neutral.40"
              rounded="md"
              _hover={{ cursor: 'pointer' }}
              onClick={() => window.location.replace('https://palmvision.studio')}
              pointerEvents="all"
              alignSelf="center"
              display="flex"
              justifyContent="center"
              alignItems="center"
              columnGap="2"
              style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 16, paddingRight: 16 }}
            >
              <Text variant="unusual-text" fontSize={16}>
                Palm Vision Studio
              </Text>
              <div style={{ transform: 'rotate(180deg)' }}>
                <ArrowLeftIcon />
              </div>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }),
);
