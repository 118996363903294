import { Box } from '@chakra-ui/react';
import { ENV } from '@config';
import Spline from '@splinetool/react-spline';
import { useEffect, useRef, useState } from 'react';

type Props = {
  onLoad: VoidFunction;
};

export const SplineObject = ({ onLoad }: Props) => {
  const [currentSplineWidth, setCurrentSplineWidth] = useState<number>(0);

  const maxWidthContainerReference = useRef<HTMLDivElement>(null);

  const debounceTimeoutReference = useRef<NodeJS.Timeout>();

  const getContainerWidth = () => maxWidthContainerReference.current?.clientWidth ?? 0;

  useEffect(() => {
    setCurrentSplineWidth(getContainerWidth());
    const listener = window.addEventListener('resize', () => {
      clearTimeout(debounceTimeoutReference.current);
      debounceTimeoutReference.current = setTimeout(() => {
        setCurrentSplineWidth(getContainerWidth());
      }, 250);
    });

    return () => {
      window.removeEventListener('resize', () => listener);
    };
  }, []);

  return (
    <Box h="100%" w="100%" ref={maxWidthContainerReference}>
      <Box h="100%" w={`${currentSplineWidth}px`} margin="auto">
        <Spline key="header-spline" scene={ENV.SPLINE_HEADER_URL} onLoad={() => setTimeout(onLoad, 500)} />
      </Box>
    </Box>
  );
};
