import { Box, Text, VStack } from '@chakra-ui/react';

type Props = {
  isMobile?: boolean;
};

export const TextBox = (props: Props) => (
  <VStack display="flex" flexDir="column" alignItems="flex-start" spacing="30px" zIndex="3" pointerEvents="none">
    <Box display="flex" flexDir="column">
      <Text variant="heading-1" whiteSpace="pre-line">
        {`Let's move\n your brand\n with animation`}
      </Text>
    </Box>

    <Box display="flex" flexDir="column">
      <Text
        variant="paragraph-1"
        {...(!props.isMobile ? { whiteSpace: 'pre-line' } : {})}
      >{`Bring your brand to life with captivating animations.\n Explore my dynamic range of animated content that help\n brands, agencies, and people make a lasting impression`}</Text>
    </Box>
  </VStack>
);
