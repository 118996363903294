import { ProjectDetails } from '../modules/project-details/ProjectDetails';
import { createBrowserRouter } from 'react-router-dom';
import { HomeModule } from '@modules';
import { AdminPanelModule } from '@kubamichalkiewicz/admin-panel-module';
import { FirebaseController } from '@services';

export enum Routes {
  ROOT = '/',
  ADMIN_PANEL = '/admin-panel',
  PROJECT_DETAILS = '/:id',
}

export const router = createBrowserRouter([
  {
    path: Routes.ROOT,
    element: <HomeModule.HomeModule />,
  },
  {
    path: Routes.ADMIN_PANEL,
    element: (
      <AdminPanelModule
        firebaseDatabaseInstance={FirebaseController.database}
        onFileUpload={FirebaseController.uploadFile}
      />
    ),
  },
  {
    path: Routes.PROJECT_DETAILS,
    element: <ProjectDetails />,
  },
]);
