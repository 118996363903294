import { NewPageAvailableModal } from './components/new-page-available-modal.component';
import { ScreenLoader } from './components/ScreenLoader/ScreenLoader';
import { HomeStoreProvider } from './contexts/HomeStore.context';
import { HomeComponent } from './Home.component';

export const HomeContainer = () => (
  <HomeStoreProvider>
    <ScreenLoader />
    <HomeComponent />
    <NewPageAvailableModal />
  </HomeStoreProvider>
);
