import { getProjectsTiles, QueryKeyEnum } from '@kubamichalkiewicz/shared';
import { FirebaseController } from '@services';
import { useQuery } from 'react-query';

export const useProjectTiles = () => {
  const { data, isLoading, isIdle } = useQuery({
    queryKey: QueryKeyEnum.PROJECT_TILES,
    queryFn: () => getProjectsTiles(FirebaseController.database),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return {
    projectTiles: data ?? [],
    isLoading,
    isIdle,
  };
};
