import { Box } from '@chakra-ui/react';
import { motion, useAnimate } from 'framer-motion';
import Lottie from 'lottie-react';
import { HomeLoading } from '@assets';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { HomeModule } from '@modules';
import { useGlobalStore } from '@services';
import { withIsMobile } from '@hocs';

export const ScreenLoader = withIsMobile<object>(
  observer(({ isMobile }) => {
    const [scope, animate] = useAnimate();

    const { isLoaded } = HomeModule.useHomeStore();

    const [isFinished, setIsFinished] = useState(false);

    const { getWasHomeModuleRenderedDuringSession } = useGlobalStore();

    const [shouldBeLoaderDisplayed] = useState(!getWasHomeModuleRenderedDuringSession());

    const animateOut = useCallback(
      () =>
        animate(
          scope.current,
          {
            opacity: 0,
          },
          { duration: 0.5 },
        ).then(() => {
          setIsFinished(true);
        }),
      [animate, scope],
    );

    useEffect(() => {
      if (isLoaded) animateOut();
    }, [isLoaded, animateOut]);

    const shouldBeHidden = isFinished || !shouldBeLoaderDisplayed;

    return (
      <Box
        display={shouldBeHidden ? 'none' : 'flex'}
        opacity="1"
        ref={scope}
        as={motion.div}
        bg="#fafafa"
        position="fixed"
        top="0"
        w="100%"
        h="100%"
        zIndex="100"
        alignItems="center"
        justifyContent="center"
      >
        <Box as={motion.div}>
          <Lottie animationData={HomeLoading} style={{ width: isMobile ? '17vw' : '13.5vw', maxWidth: '100px' }} />
        </Box>
      </Box>
    );
  }),
);
