import { Box, BoxProps, Text, VStack } from '@chakra-ui/react';

type Props = {
  title: string;
  subtitle: string;
  description: string;
  containerProps: BoxProps;
};

export const TitleBox = (props: Props) => (
  <Box
    w="100%"
    display="flex"
    flexDirection={{
      mobile: 'column',
      till1024: 'row',
    }}
    {...props.containerProps}
    rowGap="25px"
    columnGap="15px"
  >
    <VStack flex="0.55" alignItems="flex-start">
      <Text variant="heading-2" alignSelf="flex-start" pb="0.2vw">
        {props.title}
      </Text>
      <Text variant="paragraph-1">{props.subtitle}</Text>
    </VStack>
    <Text flex="0.45" variant="paragraph-1">
      {props.description}
    </Text>
  </Box>
);
